import React, { useRef, useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";
import { t } from "i18next";
import { setSidebarOpen } from "../../store/DefaultSettings";
import sidebardMenuOpenIcon from "../../assets/icons/sidebardMenuOpenIcon.svg";
import "../../assets/css/components/base/SideBar.scss";
import { menuConfig } from "../../helpers/menuItems";

const Sidebar = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const open = useSelector((state) => state.defaultSettings.sidebarOpen);
  const resourceAccess = useSelector(
    (state) => state.authStatus.resourceAccess
  );

  const dropdownRef = useRef(null);
  const [adminDropdownOpen, setAdminDropdownOpen] = useState(false);
  const [wasAdminDropdownOpen, setWasAdminDropdownOpen] = useState(false);

  const isAuth = useSelector((state) => state.authStatus.isLoggedIn);

  const sidebarWidthOpen = "17.5rem";
  const sidebarWidthClosed = "4rem";

  useEffect(() => {
    const savedSidebarState = localStorage.getItem("sidebarOpen");

    if (savedSidebarState !== null) {
      dispatch(setSidebarOpen(JSON.parse(savedSidebarState)));
    } else {
      dispatch(setSidebarOpen(true));
    }
  }, [dispatch]);

  const handleSidebarClick = () => {
    if (open) {
      setWasAdminDropdownOpen(adminDropdownOpen);
      setAdminDropdownOpen(false);
    } else {
      if (wasAdminDropdownOpen) {
        setAdminDropdownOpen(true);
      }
    }
    dispatch(setSidebarOpen(!open));
    localStorage.setItem("sidebarOpen", JSON.stringify(!open));
  };

  const toggleAdminDropdown = () => {
    if (!open) {
      dispatch(setSidebarOpen(true));
      setAdminDropdownOpen(true);
      setWasAdminDropdownOpen(true);
    } else {
      setAdminDropdownOpen(!adminDropdownOpen);
      setWasAdminDropdownOpen(!adminDropdownOpen);
    }
  };

  const handleMenuItemClick = (menu) => {
    if (menu.isDropdownTrigger) {
      toggleAdminDropdown();
    } else if (!menu.dropdownId) {
      setAdminDropdownOpen(false);
    }
  };

  useEffect(() => {
    if (open && wasAdminDropdownOpen) {
      setAdminDropdownOpen(true);
    }
  }, [open, wasAdminDropdownOpen]);

  const hasAccessToMenu = (menuRoles) => {
    if (resourceAccess.includes("laigoAdmin")) {
      return true;
    }
    return menuRoles.some((role) => resourceAccess.includes(role));
  };

  const isSelected = (path) => location.pathname === path;

  const renderSubMenuItem = (subItem, key) => {
    if (!hasAccessToMenu(subItem.roles)) {
      return null;
    }

    return (
      <Link
        to={subItem.link}
        key={key}
        className={`${
          isSelected(subItem.link) ? "selected" : ""
        } sidebar-menu-link subMenu ${
          open ? "open" : ""
        } group flex items-start gap-3.5 font-medium rounded-md z-10`}
      >
        {subItem.icon && (
          <img
            src={subItem.icon}
            alt=""
            className={`icon ${isSelected(subItem.link) ? "selected" : ""}`}
          />
        )}
        <h2
          className={`duration-75 whitespace-nowrap text-white ${
            !open && "opacity-0 translate-x-28 overflow-hidden"
          }`}
        >
          {t(subItem.name)}
        </h2>
      </Link>
    );
  };

  const renderMenuItem = (menu, index) => {
    if (!isAuth || !hasAccessToMenu(menu.roles)) {
      return null;
    }

    if (menu.isDropdownTrigger) {
      return (
        <React.Fragment key={index}>
          <Link
            className={`sidebar-menu-link adminDropDown flex items-center gap-3.5 font-medium ${
              open ? "open" : ""
            } rounded-md cursor-pointer`}
            onClick={toggleAdminDropdown}
          >
            <img className="icon" src={menu.icon} alt="" />
            <h2
              className={`duration-75 whitespace-nowrap text-white ${
                !open && "opacity-0 translate-x-28 overflow-hidden"
              }`}
            >
              {t(menu.name)}
            </h2>
          </Link>
          {adminDropdownOpen && menu.subItems && (
            <div className="submenuContainer">
              {menu.subItems.map((subItem, subIndex) =>
                renderSubMenuItem(subItem, `${index}-${subIndex}`)
              )}
            </div>
          )}
        </React.Fragment>
      );
    }

    return (
      <Link
        to={menu.link}
        key={index}
        onClick={() => handleMenuItemClick(menu)}
        className={`${
          isSelected(menu.link) ? "selected" : ""
        } sidebar-menu-link ${
          menu.dropdownId === "adminDropdown" ? "subMenu" : ""
        } ${
          open ? "open" : ""
        } group flex items-center gap-3.5 font-medium rounded-md z-10`}
      >
        {menu.icon && (
          <img
            src={menu.icon}
            alt=""
            className={`icon ${isSelected(menu.link) ? "selected" : ""}`}
          />
        )}
        <h2
          className={`duration-75 whitespace-nowrap text-white ${
            !open && "opacity-0 translate-x-28 overflow-hidden"
          }`}
        >
          {t(menu.name)}
        </h2>
      </Link>
    );
  };

  return (
    <div
      className="sidebar z-30 fixed bottom-0 bg-[#0e223a] min-h-full hidden md:block lg:block duration-500 text-gray-100 h-full"
      style={{
        width: open ? sidebarWidthOpen : sidebarWidthClosed,
        paddingTop: "60px",
        boxShadow: "5px 0 4px rgba(0, 0, 0, 0.3)",
        borderRight: "none",
      }}
    >
      <div className="pt-0 flex justify-end absolute left-[75px] h-0 z-20">
        <div
          className="transition-all duration-100 w-6 rounded-full border-[#222640ff] bg-[#222640ff] cursor-pointer align-middle mt-[-5px]"
          style={{
            height: "25px",
            marginLeft: open ? "190px" : "-100px",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={handleSidebarClick}
        >
          <img
            alt=""
            className="cursor-pointer px-[8px] py-[4px]"
            src={sidebardMenuOpenIcon}
            style={{
              transform: open ? "rotate(180deg)" : "rotate(0deg)",
            }}
          />
        </div>
      </div>

      <div className="flex flex-col items-center" ref={dropdownRef}>
        {menuConfig.map(renderMenuItem)}
      </div>
    </div>
  );
};

export default withTranslation()(Sidebar);
