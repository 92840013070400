import { createSlice } from "@reduxjs/toolkit";

const smartTableSlice = createSlice({
  name: "smartTable",
  initialState: {
    uploadState: 1,
    teJobId: 0,
    resultsForDownload: 0,
    uploadedFileName: "",
    resultsReceived: false,
    smartTableData: null,
    userDocuments: [],
    selectedDocument: null,
  },
  reducers: {
    resetSmartTableState: (state) => {
      state.uploadState = 1;
      state.uploadedFileName = "";
      state.teJobId = null;
      state.resultsReceived = false;
      state.smartTableData = null;
    },
    setUploadStateSmartTable: (state, action) => {
      state.uploadState = action.payload;
    },
    setJobIdSmartTable: (state, action) => {
      state.teJobId = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setSmartTableData: (state, action) => {
      state.smartTableData = action.payload;
    },
    setUserDocuments: (state, action) => {
      state.userDocuments = action.payload;
    },
    setSelectedDocument: (state, action) => {
      state.selectedDocument = action.payload;
    }
  },
});

export const {
  setUploadStateSmartTable,
  setJobIdSmartTable,
  setResultsForDownload,
  setUploadedFileName,
  setResultsReceived,
  setSmartTableData,
  setUserDocuments,
  setSelectedDocument,
  resetSmartTableState,
} = smartTableSlice.actions;

export default smartTableSlice.reducer;
