import React, { useState, useEffect } from "react";
import "../../assets/css/components/UI/Tooltip.scss";
import { useTranslation } from "react-i18next";
import defaultTooltipIcon from "../../assets/icons/tooltipIcon.svg";

const Tooltip = ({ children, header, content, icon, iconRight }) => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".tooltip-container") && isVisible) {
        setIsVisible(false);
      }
    };

    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isVisible]);

  const iconStyle = {
    backgroundImage: `url(${icon || defaultTooltipIcon})`,
  };

  const tooltipClass = icon ? "tooltip-content-custom" : "tooltip-content";
  const tooltipClassIcon = iconRight
    ? "tooltip-content-custom-right"
    : "tooltip-content";

  const renderContent = () => {
    if (Array.isArray(content)) {
      return content.map((item, index) => (
        <React.Fragment key={index}>
          {index > 0 && <br />}
          <span>{t(item)}</span>
        </React.Fragment>
      ));
    }
    return <span>{t(content)}</span>;
  };

  return (
    <div
      className={`tooltip-container ${icon ? "tooltip-icon-right" : ""}`}
      onClick={() => setIsVisible(!isVisible)}
    >
      <div className="tooltipIcon" style={iconStyle}></div>
      {isVisible && (
        <div className={`${tooltipClass} ${tooltipClassIcon}`}>
          {header && (
            <span className="flex mb-4">
              {t(header)} <br z />
            </span>
          )}
          {renderContent()}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
