import { createSlice } from "@reduxjs/toolkit";
import { aggregateSmartCustoms } from "../utils/aggregateSmartCustoms";

const laigosSlice = createSlice({
  name: "laigos",
  initialState: {
    availableLaigos: 0,
    usedLaigos: 0,
    expiredLaigos: 0,
    userMetrics: [],
    modelsTimeUsage: [],
    dailyUsage: [],
    customSubscriptions: [],
  },
  reducers: {
    setUserMetrics: (state, action) => {
      const { userMetrics, modelsTimeUsage, dailyUsage } = action.payload;

      state.userMetrics = userMetrics;
      state.modelsTimeUsage = aggregateSmartCustoms(modelsTimeUsage);
      state.dailyUsage = dailyUsage;
      let totalAvailableLaigos = 0;
      let totalExpiredLaigos = 0;
      let usedLaigos = 0;
      userMetrics?.forEach((subscription) => {
        totalAvailableLaigos += subscription.availableLaigos;
        totalExpiredLaigos += subscription.expiredLaigos;
        usedLaigos += subscription.usedLaigos;
      });
      state.availableLaigos = totalAvailableLaigos;
      state.expiredLaigos = totalExpiredLaigos;
      state.usedLaigos = usedLaigos;
    },
    updateModelsTimeUsage: (state, action) => {
      state.modelsTimeUsage = aggregateSmartCustoms(action.payload);
    },
    updateDailyUsage: (state, action) => {
      state.dailyUsage = action.payload;
    },
    setCustomSubscriptions: (state, action) => {
      state.customSubscriptions = action.payload;
    },
    reset: (state) => {
      state.availableLaigos = 0;
      state.usedLaigos = 0;
      state.expiredLaigos = 0;
      state.userMetrics = [];
      state.modelsTimeUsage = [];
      state.dailyUsage = [];
      state.customSubscriptions = [];
    },
  },
});

export const {
  setUserMetrics,
  updateModelsTimeUsage,
  updateDailyUsage,
  setCustomSubscriptions,
  reset,
} = laigosSlice.actions;

export default laigosSlice.reducer;