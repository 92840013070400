import React, { Suspense, useEffect, useState } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

const Users = React.lazy(() => import("../../pages/Users"));
const UserRoles = React.lazy(() => import("../../pages/UserRoles"));
const Subscription = React.lazy(() => import("../../pages/Subscription"));
const SmartTable = React.lazy(() => import("../../pages/SmartTable"));
const SmartInvoice = React.lazy(() => import("../../pages/SmartInvoice"));
const SmartCustoms = React.lazy(() => import("../../pages/SmartCustoms"));
const SmartICR = React.lazy(() => import("../../pages/SmartICR"));
const SmartPaperFlow = React.lazy(() => import("../../pages/SmartPaperFlow"));
const SmartReceipt = React.lazy(() => import("../../pages/SmartReceipt"));
const ApiPage = React.lazy(() => import("../../pages/ApiPage"));
const Loading = React.lazy(() => import("./../../pages/Loading"));
const Welcome = React.lazy(() => import("./../../pages/Welcome.js"));
const UserProfile = React.lazy(() => import("./../../pages/UserProfile.js"));
const Register = React.lazy(() => import("../../pages/Register"));

const fallback = <Loading />;

const componentMapping = {
  "/Users": Users,
  "/userRoles": UserRoles,
  "/subscription": Subscription,
  "/smartTable": SmartTable,
  "/smartInvoice": SmartInvoice,
  "/smartCustoms": SmartCustoms,
  "/smartICR": SmartICR,
  "/smartPaperFlow": SmartPaperFlow,
  "/smartReceipt": SmartReceipt,
  "/apikeys": ApiPage,
  "/userprofile": UserProfile,
};

const roleToRoutes = {
  laigoAdmin: [
    ...Object.keys(componentMapping),
    "/userprofile",
    "/subscription",
  ],
  smartTable: ["/smartTable", "/userprofile", "/subscription"],
  smartInvoice: ["/smartInvoice", "/userprofile", "/subscription"],
  smartICR: ["/smartICR", "/userprofile", "/subscription"],
  smartCustoms: ["/smartCustoms", "/userprofile", "/subscription"],
  smartAPIs: ["/apikeys", "/userprofile", "/subscription"],
  subscription: ["/subscription", "/userprofile", "/subscription"],
  smartPaperFlow: ["/smartPaperFlow", "/userprofile", "/subscription"],
  smartReceipt: ["/smartReceipt", "/userprofile", "/subscription"],
};

const AuthenticatedRoutes = ({ userRoles }) => {
  const location = useLocation();
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [initialCheckComplete, setInitialCheckComplete] = useState(false);

  useEffect(() => {
    let routes = new Set();
    userRoles.forEach((role) => {
      roleToRoutes[role]?.forEach((route) => routes.add(route));
    });
    setAllowedRoutes(Array.from(routes));
    setInitialCheckComplete(true);
  }, [userRoles]);

  if (!initialCheckComplete) {
    return fallback;
  }

  const isPathAllowed = allowedRoutes.includes(location.pathname);

  return (
    <Suspense fallback={fallback}>
      <Routes>
        {isPathAllowed ? (
          allowedRoutes.map((route) => (
            <Route
              key={route}
              path={route}
              element={React.createElement(componentMapping[route])}
            />
          ))
        ) : (
          <Route
            path="*"
            element={<Navigate replace to={allowedRoutes[0] || "/"} />}
          />
        )}
        <Route
          path="/"
          element={<Navigate replace to={allowedRoutes[0] || "/"} />}
        />
      </Routes>
    </Suspense>
  );
};

const RoutesContainer = ({ isAuth, resourceAccess }) => {
  if (!isAuth) {
    return (
      <Suspense fallback={fallback}>
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route path="/register/:hashedRole" element={<Register />} />
          <Route path="*" element={<Welcome />} />
        </Routes>
      </Suspense>
    );
  }

  return <AuthenticatedRoutes userRoles={resourceAccess} />;
};

export default RoutesContainer;
