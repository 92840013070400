import React, { useState, forwardRef } from "react";
import "../../assets/css/components/UI/Input.scss";
import { useTranslation } from "react-i18next";
import Tooltip from "./Tooltip";

const Input = forwardRef(
  (
    {
      tooltip,
      validateTrigger,
      fieldError,
      tooltipIcon,
      isSubmitted,
      dataChanged,
      isSmartPaperFlow = false,
      fieldName,
      showBorderColors = false,
      score,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [touched, setTouched] = useState(false);

    const handleBlur = () => {
      setTouched(true);
    };

    const showFieldError = !!fieldError;
    const showError =
      props.required &&
      (touched || validateTrigger) &&
      !props.value &&
      !fieldError;

    const inputClass =
      isSubmitted && dataChanged
        ? "inputSubmitted"
        : dataChanged
        ? "inputChanged"
        : "";

    const inputSizeClass = isSmartPaperFlow
      ? fieldName === "Summary"
        ? "customTextarea-large"
        : fieldName === "DetailedSummary"
        ? "customTextarea-xlarge"
        : ""
      : "";

    const getScoreClass = () => {
      if (!showBorderColors || score === undefined) return "";
      
      const scoreNum = parseFloat(score);
      if (isNaN(scoreNum)) return "";
      
      if (scoreNum >= 0.9) return "score-high";
      if (scoreNum >= 0.8) return "score-medium";
      return "score-low";
    };

    const scoreClasses = showBorderColors ? `score-input ${getScoreClass()}` : "";

    return (
      <div
        className={`${props.wrapperClassName} inputValue flex flex-col position-relative`}
      >
        <div className="">
          <label className={`${props.labelClass} labelValue font-normal`}>
            {t(props.inputLabel)}
            {tooltip && !props.tooltipRight && (
              <Tooltip
                header={props.tooltipHeader}
                content={props.tooltipContent}
                icon={tooltipIcon}
                tooltipIcon={tooltipIcon}
              />
            )}
          </label>
          <div className="relative flex items-center">
            <div className="flex-grow">
              {fieldName === "Summary" || fieldName === "DetailedSummary" ? (
                <textarea
                  className={`customTextarea ${props.className} ${
                    props.parentDivClass
                  } ${
                    showError || showFieldError ? "errorInput" : ""
                  } ${inputClass} ${inputSizeClass} w-full`}
                  onClick={props.onClick}
                  onBlur={handleBlur}
                  disabled={props.disabled}
                  required={props.required}
                  onChange={props.onChange}
                  id={props.id}
                  value={props.value}
                  name={props.name}
                  ref={ref}
                  placeholder={props.placeholder}
                  readOnly={props.readOnly}
                />
              ) : (
                <input
                  className={`customInput ${props.className} ${
                    props.parentDivClass
                  } ${
                    showError || showFieldError ? "errorInput" : ""
                  } ${inputClass} ${inputSizeClass} w-full ${scoreClasses}`}
                  onClick={props.onClick}
                  onBlur={handleBlur}
                  disabled={props.disabled}
                  required={props.required}
                  onChange={props.onChange}
                  onKeyDown={props.onKeyDown}
                  type={props.inputType}
                  id={props.id}
                  value={props.value}
                  checked={props.checked === true}
                  onInvalid={(e) => e.preventDefault()}
                  name={props.name}
                  ref={ref}
                  placeholder={props.placeholder}
                  defaultValue={props.defaultValue}
                  readOnly={props.readOnly}
                  min={props.inputType === "number" ? props.min : ""}
                />
              )}
            </div>
            {tooltip && props.tooltipRight && (
              // <div className="-mt-5 mr-6 flex items-center">
              <div className="-mt-5 flex items-center">
                <Tooltip
                  header={props.tooltipHeader}
                  content={props.tooltipContent}
                  icon={tooltipIcon}
                  tooltipIcon={tooltipIcon}
                  tooltipRight={true}
                  iconRight={true}
                />
              </div>
            )}
          </div>
        </div>
        {props.error && (
          <div>
            <span className="text-red-500">{t(props.errorText)}</span>
          </div>
        )}
        {showFieldError && <div className="mandatoryField">{fieldError}</div>}
        {showError && !showFieldError && (
          <div className="mandatoryField">{t("Mandatory_Field")}</div>
        )}
      </div>
    );
  }
);

export default Input;