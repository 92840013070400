import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  uploadState: 1,
  resultsForDownload: null,
  resultsReceived: false,
  uploadedFileName: "",
  receiptData: {},
  ieJobId: null,
};

const smartReceiptSlice = createSlice({
  name: "smartReceipt",
  initialState,
  reducers: {
    setUploadStateSmartReceipt: (state, action) => {
      state.uploadState = action.payload;
    },
    setResultsForDownload: (state, action) => {
      state.resultsForDownload = action.payload;
    },
    setResultsReceived: (state, action) => {
      state.resultsReceived = action.payload;
    },
    setUploadedFileName: (state, action) => {
      state.uploadedFileName = action.payload;
    },
    setSmartReceiptData: (state, action) => {
      state.receiptData = action.payload;
    },
    setJobIdSmartReceipt: (state, action) => {
      state.ieJobId = action.payload;
    },
  },
});

export const {
  setUploadStateSmartReceipt,
  setResultsForDownload,
  setResultsReceived,
  setUploadedFileName,
  setSmartReceiptData,
  setJobIdSmartReceipt,
} = smartReceiptSlice.actions;

export default smartReceiptSlice.reducer;
