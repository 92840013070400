export const aggregateSmartCustoms = (modelsTimeUsage) => {
  let smartCustomsTotal = 0;
  const aggregatedUsage = modelsTimeUsage.reduce((acc, item) => {
    const [key] = Object.keys(item);
    if (key.startsWith("smartCustoms_")) {
      smartCustomsTotal += Object.values(item)[0];
    } else {
      acc.push(item);
    }
    return acc;
  }, []);

  if (smartCustomsTotal > 0) {
    aggregatedUsage.push({ smartCustoms: smartCustomsTotal });
  }

  return aggregatedUsage;
};
