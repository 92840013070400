import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

import AuthCheck from "./AuthSlice";
import Laigos from "./LaigosSlice";
import ApiPage from "./ApiPageSlice";
import Users from "./Users";
import Subscription from "./SubscriptionSlice";
import SmartTable from "./SmartTableSlice";
import SmartPaperFlowSlice from "./SmartPaperFlowSlice";
import SmartInvoice from "./SmartInvoiceSlice";
import SmartReceipt from "./SmartReceiptSlice";
import SmartCustoms from "./SmartCustomsSlice";
import SmartICR from "./SmartICRSlice";
import SmartTools from "./SmartToolsSlice";
import Footer from "./FooterSlice";
import DefaultSettings from "./DefaultSettings";
import notificationReducer from "./NotificationSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const reducers = combineReducers({
  authStatus: AuthCheck,
  laigos: Laigos,
  apiPage: ApiPage,
  Users: Users,
  footer: Footer,
  subscription: Subscription,
  smartTable: SmartTable,
  smartPaperFlow: SmartPaperFlowSlice,
  smartInvoice: SmartInvoice,
  smartReceipt: SmartReceipt,
  smartCustoms: SmartCustoms,
  smartICR: SmartICR,
  smartTools: SmartTools,
  defaultSettings: DefaultSettings,
  notification: notificationReducer,
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});

export default store;
