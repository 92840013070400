const isProduction = process.env.NODE_ENV === 'production';

const initOptions = {
  url: "https://account.laigo.io", // the url of Keycloak server without /auth with new version of keycloak
  realm: "SmartTools", // the name of the realm case sensitive
  clientId: "laigo-api-key", // the name of the Client case sensitive
  onLoad: "check-sso", // Leave as it is
  KeycloakResponseType: "code", // Leave as it is
  pkceMethod: "S256", // Leave as it is  to USE PKCE flow
  checkLoginIframe: false,
  silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
};

window.serverUrl = "https://use.laigo.io";
window.tokenExpireTime = 150; // if the token expire set in keycloak is set to 1minute here should be half of it 1 = 60 / 2 = 30

const stripePublishableKey  = isProduction
  ? "pk_live_51P4I8MRubObAbCe9OyyHaB4w8IPbhtLCGLLr5x5H7uFEPvZf9FDQVMSBCPr6RNtBlCbaRuGpcgr07oVlU9kSHnGi00Y933UX0I" // Production Stripe publishable key
  : "pk_test_51P4I8MRubObAbCe9N8stzJR7lFwtN09Itg1E0lfLEGO81HObDvhOSwHnmaztj4T7g4WTj0TaKTo8A6NO3BT4nqqC004fUk9Pax"; // Test Stripe publishable key

export { initOptions, stripePublishableKey };
