import React, { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RoutesContainer from "./components/app/Routes";
import Loading from "./pages/Loading";
import Sidebar from "./components/base/Sidebar";
import Header from "./components/base/Header";
import Breadcrumbs from "./components/base/Breadcrumbs";
import NotificationBox from "./components/UI/NotificationBox";
import Footer from "./components/base/Footer";
import InitialOverlay from "./components/UI/InitialOverlay";
import { useLocation } from "react-router-dom";
import { ensureCustomer } from "./store/helpers/subscription-actions";
import { Toaster } from "./components/UI/Shad/toaster";
const fallback = <Loading />;

export default function App() {
  const location = useLocation();
  const isAuth = useSelector((state) => state.authStatus.isLoggedIn);
  const sidebarOpen = useSelector((state) => state.defaultSettings.sidebarOpen);
  const resourceAccess = useSelector(
    (state) => state.authStatus.resourceAccess
  );
  // document.documentElement.classList.add("dark");

  const keycloak = useSelector((state) => state.authStatus.user?.tokenParsed);
  const dispatch = useDispatch();

  useEffect(() => {
    const ensureCustomerCalled = localStorage.getItem("ensureCustomerCalled");

    if (keycloak?.sub && !ensureCustomerCalled) {
      dispatch(ensureCustomer(keycloak.sub));
      localStorage.setItem("ensureCustomerCalled", "true");
    }
  }, [dispatch, keycloak]);

  return (
    <div>
      <Suspense fallback={fallback}>
        <div
          className={`flex ${sidebarOpen ? "sidebarOpen" : "sidebarClosed"} ${
            /^\/register/.test(location.pathname)
              ? "mb-0"
              : "appContainer h-max mb-16"
          }`}
        >
          {isAuth && <InitialOverlay />}
          {isAuth && <Header />}
          {isAuth && <Sidebar />}
          <Toaster />

          <div
            className={`flex flex-col flex-grow overflow-hidden ${
              /^\/register/.test(location.pathname)
                ? "register-page-container"
                : "contentArea"
            }`}
          >
            <NotificationBox />
            {isAuth && <Breadcrumbs />}
            <div
              className={`${
                !/^\/register/.test(location.pathname) &&
                "mt-[90px] mobile:mx-5 lg:ml-2  md:ml-10 lg:mr-12 lg:rounded-lg"
              }`}
            >
              <RoutesContainer
                isAuth={isAuth}
                resourceAccess={resourceAccess}
              />
            </div>
          </div>
        </div>
        {isAuth && <Footer />}
      </Suspense>
    </div>
  );
}
